import { Box, Card, Typography } from '@mui/material'; 
import Stack from '@mui/material/Stack';



const EventBox = ({ data }) => (
  <>
    <Card>
      <Box
        sx={{ 
          py: 4.0,
          bgcolor: data.color,
          color: "#ffffff",
        }}
      >
        {data.name && (
          <Stack
            key="Stack"
            direction="row"
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{ paddingLeft: 2, paddingRight: 2 }}
          >
            {/* <Avatar src={data.icon} alt={data.name} sx={{bgcolor: 'transparent'}} /> */}
            <img src={data.icon} height={40} />
            <Typography
              variant="subtitle1"
              color="inherit"
              sx={{
                textAlign: "right",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {data.name}
            </Typography>
          </Stack>
        )}
        {!data.name && <Box sx={{ p: 1.15 }} />}
      </Box>
    </Card>
  </>
);

export default EventBox;