import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { useContext } from "react";
import {AppContext} from "context/AppContext"; 
import { Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
 
const ResponsiveDialog = ({open, onDialogClose, hasContent, msgTitle, msgBody }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md')); 
  const { t } = useTranslation();
  const ctx = useContext(AppContext); 

  const msg = {
    msgError: (
      <React.Fragment>
        {hasContent && <Typography sx={{ lineHeight: "1.7" }}>{msgBody}</Typography>}
        {!hasContent && (
          <Typography sx={{ lineHeight: "1.7" }}>
            {t("We-couldnt-authenticate-you-as")}
            <strong> {ctx.useremail}. </strong>
            {t("Please-sign-in-to-MS-Teams")}
          </Typography>
        )}
      </React.Fragment>
    ),
  };
 

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        id="responsive-dialog-title"
        sx={{ pt: 2, mx: "auto", textAlign: "center" }}
      >
        {hasContent && <h2>{msgTitle}</h2>}
        {!hasContent && <h2>{t("Unable-to-login")}</h2>}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{msg["msgError"]}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ResponsiveDialog;