// material-ui
import { Link, Typography, Stack } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //


const AuthFooter = () => {
  const dt = new Date();

  return (
    <Stack direction="row" justifyContent="center">
      <Typography variant="subtitle2" component={Link} href="https://www.findwhere.com" target="_blank" underline="hover">
        © {dt.getFullYear()} Findwhere. All Rights Reserved.
      </Typography>
    </Stack>
  );
}

export default AuthFooter;
