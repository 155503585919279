import { Avatar, Grid, Typography } from "@mui/material";
import Stack from '@mui/material/Stack'; 
import { AppContext } from "context/AppContext";
import { useContext } from "react"; 
import { useTranslation } from "react-i18next";


const UserInfo = () => { 
    const ctx = useContext(AppContext);
    const { t } = useTranslation();

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{paddingTop: 3}}>
            <Stack direction="row" spacing={2}>
                <Avatar alt="User Profile Image" sx={{
                    width: 60,
                    height: 60,
                    margin: '8px 0 8px 8px !important'
                }} src={ctx.profileImage} />

                <Stack direction='column' >
                    <Typography variant="h2" sx={{fontWeight: 400}}>
                        {t('Hi')}, {ctx.profileFirstName === '' ? 'user' : ctx.profileFirstName}
                    </Typography>
                    <Typography variant="subtitle1" sx={{paddingTop: 1}}>
                        {ctx.useremail}
                    </Typography>
                    <Stack direction='row' alignItems="center">
                        <Typography variant="body2">
                        {t("Status")}:
                        </Typography>
                        <Typography variant="subtitle1" sx={{paddingLeft:.5, pt: .3}}>
                            {ctx.status ? t('Connected') : t('Not-connected')}
                        </Typography>
                    </Stack>

                </Stack>
            </Stack>
        </Grid>
    );
}

export default UserInfo;
