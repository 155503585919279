import React, { useState } from "react";
import { Link } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

// third party
import * as Yup from "yup";
import { useFormik } from "formik";

// project imports
import { requestForNewAccount } from "apis/httpcom";
import NewAccountDialog from "views/dialogs/NewAccountDialog";
import { useTranslation } from "react-i18next";

const NewAccountForm = ({ ...others }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleDialogClose = () => {
    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      message: "",
      termsAndConditions: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("Email-is-required"))
        .max(255)
        .required(t("Email-is-required")),
      firstName: Yup.string().max(50).required(t("First-Name-is-required")),
      lastName: Yup.string().max(50).required(t("Last-Name-is-required")),
      message: Yup.string().max(50).required(t("Message-is-required")),
      termsAndConditions: Yup.boolean().oneOf(
        [true],
        t("Please-accept-Terms-and-Conditions")
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      const response = await requestForNewAccount(
        values.email,
        values.firstName,
        values.lastName,
        values.message
      );

      var data = await response.json();
      if (data && data.level) {
        if (data.level === 1) {
          console.log("successfully sent request");
          resetForm();
          setOpen(true);
          return;
        }
      }

      //show error msg dialog box
      setOpen(true);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={matchDownSM ? 0 : 2}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={Boolean(
                formik.touched.firstName && formik.errors.firstName
              )}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="firstName">{t("First-Name")}</InputLabel>
              <OutlinedInput
                id="firstName"
                type="firstName"
                value={formik.values.firstName}
                name="firstName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {formik.errors.firstName}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.lastName && formik.errors.lastName)}
              sx={{ ...theme.typography.customInput }}
            >
              <InputLabel htmlFor="firstName">{t("Last-Name")}</InputLabel>
              <OutlinedInput
                id="lastName"
                type="lastName"
                value={formik.values.lastName}
                name="lastName"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {formik.errors.lastName}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <FormControl
          fullWidth
          error={Boolean(formik.touched.email && formik.errors.email)}
          sx={{ ...theme.typography.customInput }}
        >
          <InputLabel htmlFor="outlined-adornment-email-register">
            {t("Email-Address")}
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-email-register"
            type="email"
            value={formik.values.email}
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            inputProps={{}}
          />
          {formik.touched.email && formik.errors.email && (
            <FormHelperText error id="standard-weight-helper-text--register">
              {formik.errors.email}
            </FormHelperText>
          )}
        </FormControl>
        <Grid container spacing={matchDownSM ? 0 : 2}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.message && formik.errors.message)}
              sx={{ ...theme.typography.customInput }}
            >
              {/* <InputLabel htmlFor="message">Message</InputLabel> */}
              <OutlinedInput
                id="message"
                type="message"
                placeholder={t("Type-message-here")}
                value={formik.values.message}
                name="message"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                multiline
                rows={3}
              />
              {formik.touched.message && formik.errors.message && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {formik.errors.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.termsAndConditions}
                  onChange={formik.handleChange}
                  name="termsAndConditions"
                  color="primary"
                />
              }
              label={
                <Typography variant="subtitle1">
                  {t("Agree-with")} &nbsp;
                  <Typography
                    variant="subtitle1"
                    component={Link}
                    target="_blank"
                    to="http://findwhere.com/documenten/"
                  >
                    {t("Terms-&-Conditions")}
                  </Typography>
                </Typography>
              }
            />
            {formik.touched.termsAndConditions &&
              formik.errors.termsAndConditions && (
                <FormHelperText
                  error
                  id="standard-weight-helper-text--register"
                >
                  {formik.errors.termsAndConditions}
                </FormHelperText>
              )}
          </Grid>
        </Grid>
        {formik.errors.submit && (
          <Box sx={{ mt: 3 }}>
            <FormHelperText error>{formik.errors.submit}</FormHelperText>
          </Box>
        )}

        <Box sx={{ mt: 2 }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
          >
            {formik.isSubmitting ? t("Please-wait") : t("Submit")}
          </LoadingButton>
        </Box>
      </form>

      <NewAccountDialog
        title={t("Success!")}
        message={t("We-have-received-your-request")}
        open={open}
        onDialogClose={handleDialogClose}
      />
    </>
  );
};

export default NewAccountForm;
