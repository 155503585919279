import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NewAccountForm from "./components/NewAccountForm";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";

// project imports
import { useContext } from "react";

// assets
import ResponsiveDialog from "views/dialogs/ResponsiveDialog";
import { LoadingButton } from "@mui/lab";
import { performSignInUsingTeamsId, sessionLogin } from "apis/httpcom";
import { AppContext } from "context/AppContext";
import { app } from "@microsoft/teams-js";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const RequestNewAccount = (props) => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);

  const [msgStatus, setMsgStatus] = useState({
    shouldOpen: false,
    hasContent: false,
    msgTitle: "",
    msgBody: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const ctx = useContext(AppContext);
  const [hasInitialized, setHasInitialized] = useState(false);
  const { t } = useTranslation();

  const existingAccountButtonHandler = async () => {
    setIsSubmitting(true);

    try {
      var response = await performSignInUsingTeamsId(ctx.useremail);
      if (response.status === 200) {
        var data = await response.json();

        if (data && data.level) {
          if (data.level === 1) {
            console.log("successfully authenticated", data);

            //set session
            await sessionLogin({
              email: btoa(ctx.useremail),
              at: data.returnValue.authToken,
            });

            //update response data
            ctx.updateProfileData(data);

            //navigate to events screen
            navigate("/main/ems");
            return;
          }
        }
      }
    } catch (error) {
      // TypeError: Failed to fetch
      console.log("There was an error", error);
    }

    //show error msg dialog box
    setIsSubmitting(false);
    showFailedToLoginMsg();
  };

  const showFailedToLoginMsg = () => {
    toast.error(
      <>
        <Stack>
          <Typography variant="subtitle1">{t("Unable-to-login")}</Typography>
          <Typography variant="caption">
            {t("Unable-to-login-please-try-again-after-some-time")}
          </Typography>
        </Stack>
      </>,
      {
        position: "top-right",
      }
    );
  };

  const handleDialogClose = () => {
    setMsgStatus({ shouldOpen: false });
  };

  useEffect(() => {
    async function onLoaded() {
      try {
        //check if app has initialized
        const isInitialied = app.isInitialized();

        if (isInitialied) {
          setHasInitialized(isInitialied);
        }
      } catch (error) {
        console.log("ex", error);
      }
    }

    onLoaded();
  }, []);

  return (
    <>
      <Grid container direction="column" justifyContent="center" spacing={2}>
        {hasInitialized && (
          <>
            <Grid item xs={12}>
              <LoadingButton
                variant="outlined"
                loading={isSubmitting}
                fullWidth
                onClick={existingAccountButtonHandler}
                size="large"
                sx={{
                  color: "grey.700",
                  backgroundColor: theme.palette.ems.btnLight,
                  borderColor: theme.palette.grey[400],
                }}
              >
                {t("Continue")}
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ alignItems: "center", display: "flex" }}>
                <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                <Button
                  variant="outlined"
                  sx={{
                    cursor: "unset",
                    m: 2,
                    py: 0.5,
                    px: 7,
                    borderColor: `${theme.palette.grey[100]} !important`,
                    color: `${theme.palette.grey[900]}!important`,
                    fontWeight: 500,
                    borderRadius: `${customization.borderRadius}px`,
                  }}
                  disableRipple
                  disabled
                >
                  {t("OR")}
                </Button>
                <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
              </Box>
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Box sx={{ mb: 2, pl: 3, pr: 3 }}>
            <Typography variant="subtitle2" textAlign={"center"}>
              {t("If-you-are-interested-in-our-services")}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <ResponsiveDialog
        open={msgStatus.shouldOpen}
        hasContent={msgStatus.hasContent}
        msgTitle={msgStatus.msgTitle}
        msgBody={msgStatus.msgBody}
        onDialogClose={handleDialogClose}
      />
      <NewAccountForm others={props} />
    </>
  );
};

export default RequestNewAccount;
