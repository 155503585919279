import languages from "./languages";

export const fwLightColor = '#2B6CAF';
export const supportLanguages = ['en', 'nl', 'de', 'es', 'fr'];

export function getFormattedDate(date) {
  const month = date.getMonth() + 1;
  const dt = date.getDate();
  const year = date.getFullYear().toString().slice(-2);
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${dt}-${month}-${year} ${hours}:${minutes}`;
}


export function parseCertDate(date) {
  let myDate = new Date(20 + date.slice(0, 2), date.slice(2, 4), date.slice(4, 6));

  let month = String(myDate.getMonth()).padStart(2, '0');
  let dt = String(myDate.getDate()).padStart(2, '0');
  let year = myDate.getFullYear().toString();

  return `${year}-${month}-${dt}`;
}

export function defaultCertDate(date, addDay) {
  if (addDay > 0) {
    date.setDate(date.getDate() + addDay)
  }

  let month = String(date.getMonth()).padStart(2, '0');
  let dt = String(date.getDate()).padStart(2, '0');
  let year = date.getFullYear().toString();

  return `${year}-${month}-${dt}`;
}

export function formatStrDtInDD_MM_YYYYFormat(date) {
  date = date.replace(/-/g, '');
  let myDate = new Date(date.slice(0, 4), date.slice(5, 6), date.slice(6, 8));

  let month = String(myDate.getMonth()).padStart(2, '0');
  let dt = String(myDate.getDate()).padStart(2, '0');
  let year = myDate.getFullYear().toString();

  let formattedDate = `${dt}-${month}-${year}`;
  return formattedDate;
}

export function getFileExtension(filename) {
  var ext = filename.substr(filename.lastIndexOf('.') + 1);
  return ext.toLowerCase();
}

export function isStringEmpty(value) {
  return (value == null || (typeof value === "string" && value.trim().length === 0));
}



export const getBase64OfFile = async (file) => {
  //Method 1
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    }
    reader.onerror = reject;
  })
 
  //method 2
  // return new Promise((resolve, reject) => {
  //   var reader = new FileReader();
  //   console.log('fileType', file);
  //   if (file) {
  //     reader.readAsDataURL(file);
  //   } 

  //   reader.onloadend = () => {
  //     resolve(reader.result);
  //   }
  //   reader.onerror = reject;
  // })
}

export const isValidFile = (filepath) => {
  if (filepath) {
    let ext = getFileExtension(filepath);
    //jpg, .jpeg, .png, .pdf
    if (ext.includes('jpg') || ext.includes('jpeg') || ext.includes('png') || ext.includes('pdf')) {
      return true;
    }
  }
  return false;
}

export const getFullLanguageName = (languageCode) => {  
  return languages[languageCode];
}