import { AppContext } from "context/AppContext";
import { useContext, useEffect } from "react";

const UserHubSignalr = ({ children }) => {
  console.log("Userhubsignalr A");

  const ctx = useContext(AppContext);

  useEffect(() => {
    console.log("Userhubsignalr B", ctx.useremail);

    if (ctx.useremail) {
      ctx.createHubConnection(ctx.useremail);
    }

    return () => {
      ctx.stopConnection(); 
    };
  }, [ctx]);

  return <>{children}</>;
};

export default UserHubSignalr;
