// material-ui
import { Grid } from "@mui/material";

// project imports
import SendEventForm from "./SendEventForm";
import NextBackButton from "./NextBackButton";
import SendCardWrapper from "./SendEventCardWrapper";

const SendEvent = ({ switchScreen, event }) => {
  return (
    <Grid
      item
      sx={{
        m: { xs: 1, sm: 3 },
        mb: 0,
        minWidth: { xs: 100, sm: 500, md: 600, lg: 600, xl: 700 },
      }}
    >
      <SendCardWrapper
        header={event.name}        
        headerColor={event.color}
        icon={event.icon}        
        action={
          <NextBackButton isSendButton={false} switchScreen={switchScreen} />
        }
      >
        <SendEventForm event={event} />
      </SendCardWrapper>
    </Grid>
  );
};

export default SendEvent;
