// const EVENTS_DATA = Array.from(Array(10), (_, i) => i + 1);
// export default EVENTS_DATA;

const EVENTS_DATA = [
    {
        "color": "#5446e1",
        "name": "QR Event",
        "icon": "https://ems.findwhere.com/ems-admin/faces/javax.faces.resource/img/calamity_icons/white/cross.png?ln=default&v=1_4",
        "eventId": 964
    },
    {
        "color": "#9c7a2f",
        "name": "test contact sms and email",
        "icon": "https://ems.findwhere.com/ems-admin/faces/javax.faces.resource/img/calamity_icons/white/black-1.png?ln=default&v=1_4",
        "eventId": 748
    },
    {
        "color": "#ff7e5f",
        "name": "BHV Spain Madrid",
        "icon": "https://ems.findwhere.com/ems-admin/faces/javax.faces.resource/img/calamity_icons/white/boat.png?ln=default&v=1_4",
        "eventId": 696
    },
    {
        "color": "#e0b043",
        "name": "BHV-test",
        "icon": "https://ems.findwhere.com/ems-admin/faces/javax.faces.resource/img/calamity_icons/white/drink.png?ln=default&v=1_4",
        "eventId": 632
    },
    {
        "color": "#3dfa69",
        "name": "Event expiration",
        "icon": "https://ems.findwhere.com/ems-admin/faces/javax.faces.resource/img/calamity_icons/white/cup.png?ln=default&v=1_4",
        "eventId": 730
    },
    {
        "color": "#b542e8",
        "name": "emergency number",
        "icon": "https://ems.findwhere.com/ems-admin/faces/javax.faces.resource/img/calamity_icons/white/hand-1.png?ln=default&v=1_4",
        "eventId": 997
    }
];

export default EVENTS_DATA;