import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { 
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import {
  AttachFileOutlined, 
  DownloadOutlined, 
} from "@mui/icons-material"; 
import {
  defaultCertDate,
  formatStrDtInDD_MM_YYYYFormat,
  getBase64OfFile,
  getFileExtension,
  isStringEmpty,
  isValidFile,
  parseCertDate,
} from "views/utilities/utility";
import { LoadingButton } from "@mui/lab";
import { updateCertificateDetails } from "apis/httpcom";
import { AppContext } from "context/AppContext";
import OkDialog from "ui-component/dialogs/OkDialog";
import { any } from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


const CertificateDialog = ({
  open,
  canEdit,
  handleClose,
  skillId,
  certificate,
}) => {

  const theme = useTheme();
  const { t } = useTranslation();

  const [certFile, setCertFile] = useState({
    default: t("Select a file"),
    filepath: "",
    filename: "",
    fileobj: any,
  });

  const [certDate, setCertDate] = useState(
    certificate
      ? parseCertDate(certificate.certificateDate, "yyMMddHHmmss")
      : defaultCertDate(new Date(), 0)
  );
  const [validTill, setValidTill] = useState(
    certificate
      ? parseCertDate(certificate.validTill, "yyMMddHHmmss")
      : defaultCertDate(new Date(), 1)
  );
  const [dialogOpen, setdialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const ctx = useContext(AppContext);

  const [{ title, message, isSuccess }, setDialogState] = useState({
    title: "",
    message: "",
    isSuccess: false,
  });

  const handleFileSelect = (e) => {
    console.log(e.target.value);
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;

    setCertFile({
      filename: name,
      filepath: e.target.value,
      fileobj: file,
    });
  };

  const handleDialogClose = () => {
    setIsSubmitting(false);
    setdialogOpen(false);    
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    //check if user has selected valid file
    var certFileBase64 = "";
    var mediaType = 1;

    if (isValidFile(certFile.filepath)) {
      //set media type 2 if PDF else default to 1
      mediaType = getFileExtension(certFile.filepath).includes("pdf") ? 2 : 1;

      //obtain base64
      certFileBase64 = await getBase64OfFile(certFile.fileobj);
      if (!isStringEmpty(certFileBase64)) {
        certFileBase64 = certFileBase64.split(",")[1];
      }
    }

    //send request
    let response = await updateCertificateDetails(
      ctx.authToken,
      skillId,
      formatStrDtInDD_MM_YYYYFormat(certDate),
      formatStrDtInDD_MM_YYYYFormat(validTill),
      mediaType,
      certFileBase64
    );

    setIsSubmitting(true);
    const data = await response.json();
    setIsSubmitting(false);
    if (data && data.level) {
      if (data.level === 1) {
        //parsing response
        ctx.updateSkills(data.returnValue.skills);

        //show success msg
        showSuccessMsg();         
        handleClose();
        return;
      }
    }
    //show error dialog msg
    await showFailedMsg();     
  };

  const showSuccessMsg = () => {
    toast.success(
      <>
        <Stack>
          <Typography variant="subtitle1">{t("Certificate-updated")}!</Typography>
          <Typography variant="caption">
            {t("Certificate-details-updated-successfully")}".
          </Typography>
        </Stack>
      </>,
      {
        position: "top-right",
      }
    );
  };

  const showFailedMsg = () => {
    return new Promise((resolve, reject) => {
      setIsSubmitting(false);
      toast.error(
        <>
          <Stack>
            <Typography variant="subtitle1">{t("Update-failed")}!</Typography>
            <Typography variant="caption">
            {t("Unable-to-update-certificate-details-please-try-again")}.
            </Typography>
          </Stack>
        </>,
        {
          position: "top-right",
        }
      );
   }); 
  };
 
  const handleValidTillDtChange = (e) => {
    setValidTill(e.target.value);
  };

  const handleCertDateChange = (e) => {
    setCertDate(e.target.value);
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>
          <Typography variant="h3" color="text.primary">
            {canEdit && <>{t("Update-Certificate-Details")}</>}
            {!canEdit && <>{t("Add-Certificate-Details")}</>}
          </Typography>
        </DialogTitle>

        <Divider
          sx={{
            opacity: 1,
            borderColor: theme.palette.primary.light,
          }}
        />

        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {canEdit && (
                <Button size="large" variant="outlined" fullWidth>
                  <Link
                    target="_blank"
                    href={certificate.originalPath}
                    sx={{
                      textDecoration: "none",
                      paddingTop: "3px",
                      marginBottom: "2px",
                    }}
                  >
                    <Stack direction="row" spacing={2}>
                      <DownloadOutlined />
                      {t("Download-certificate")}
                    </Stack>
                  </Link>
                </Button>
              )}

              <Button
                fullWidth
                component="label"
                size="large"
                variant="outlined"
                sx={{ marginRight: "1rem", marginTop: "10px" }}
              >
                {certFile.filename && certFile.filename}
                {!certFile.filename && (
                  <Stack direction="row">
                    <AttachFileOutlined />
                      {t("Update-Certificate-File")}
                  </Stack>
                )}
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  hidden
                  onChange={handleFileSelect}
                />
              </Button>
            </Grid>

            <Grid item spacing={1} xs={12}>
              <TextField
                fullWidth
                sx={{ mt: 3 }}
                id="certDate"
                name="certDate"
                label={t("Certificate-Date")}
                type="date"
                onChange={handleCertDateChange}
                defaultValue={certDate}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                fullWidth
                sx={{ mt: 3 }}
                id="validTillDate"
                name="validTillDate"
                label={t("Valid-Until")}
                type="date"
                onChange={handleValidTillDtChange}
                defaultValue={validTill}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <LoadingButton
            loading={isSubmitting}
            size="large"
            type="submit"
            color="primary"
          >
            {t("Update")}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {dialogOpen && (
        <OkDialog
          title={title}
          message={message}
          isSuccess={isSuccess}
          open={dialogOpen}
          handleClose={handleDialogClose}
        />
      )}
    </>
  );
};

export default CertificateDialog;
