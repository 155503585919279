import PropTypes from "prop-types";

// material-ui
import { Grid } from "@mui/material";
import EventBox from "../events/EventBox";
import EventsData from "../events/EventsData";

// project imports
import EventsWrapper from "../events/EventsWarpper";
import UserInfo from "../events/UserInfo";
import Events from "views/events/Events";
import SendEvent from "views/events/SendEvent";
import { useEffect, useState } from "react";
import OkDialog from "ui-component/dialogs/OkDialog";
 
EventBox.propTypes = {
  bgcolor: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  dark: PropTypes.bool,
};

// ===============================|| UI COLOR ||=============================== //

const eventSelectHandler = (eventValue) => {
  console.log(eventValue);
};

const eventCountStatus = () => {
  const eventStr = EventsData.length == 1 ? "event" : "events";
  return `Showing ${EventsData.length} ${eventStr}.`;
};

const EMSDashboard = () => {
  console.log("EMSDashboard");

  

  const [showAllEventsScreen, setShowAllEventsScreen] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(undefined);

  const [dialogOpen, setdialogOpen] = useState(false);

  const [{ title, message, isSuccess }, setDialogState] = useState({
    title: "",
    message: "",
    isSuccess: false,
  });

  

  const switchScreen = () => { 
    //switch to Send Event screen
    setShowAllEventsScreen(!showAllEventsScreen);
  };

  const updateSelectEvent = (userEvent) => {    
    if (userEvent !== undefined) {
      setSelectedEvent(userEvent);
    }
  };

  const handleDialogClose = () => {
    setdialogOpen(false);
  };

  return (
    <EventsWrapper>
      <Grid container direction="column" justifyContent="space-evenly">
        <Grid item xs={12}>
          <UserInfo />
          <Grid container justifyContent="center" alignItems="center">
            {showAllEventsScreen && (
              <Events
                switchScreen={switchScreen}
                updateSelectEvent={updateSelectEvent}
              />
            )}
            {!showAllEventsScreen && (
              <SendEvent switchScreen={switchScreen} event={selectedEvent} />
            )}
          </Grid>
        </Grid>
        {dialogOpen && (
          <OkDialog
            title={title}
            message={message}
            isSuccess={isSuccess}
            open={dialogOpen}
            handleClose={handleDialogClose}
          />
        )}
      </Grid>
    </EventsWrapper>
  );
};

export default EMSDashboard;
