import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import SendEvent from "views/events/SendEvent";
import EMSDashboard from "views/dashboard/EMSDashboard";
import Settings from "views/settings/Settings";
import MainLayout from "layout/MainLayout";
import UserHubSignalr from "views/user-hub-signalr";

// dashboard routing
const Events = Loadable(lazy(() => import("views/events/Events")));

const MainRoutes = {
  path: "/main",
  element: (
    <UserHubSignalr>
      <MainLayout />
    </UserHubSignalr>
  ),
  children: [
    {
      path: "ems",
      element: <EMSDashboard />,
    },
    {
      path: "events",
      element: <Events />,
    },
    {
      path: "send-event",
      element: <SendEvent />,
    },
    {
      path: "settings",
      element: <Settings />,
    },
  ],
};

export default MainRoutes;
