import { Outlet } from 'react-router-dom';

// project imports
import Customization from '../Customization';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  console.log('minimal layout');

  return (
    <>
      <Outlet /> 
    </>
  );
}

export default MinimalLayout;
