
import { useRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Chip, Typography, Stack, Box } from '@mui/material'; 

// assets
import { IconArrowLeft, IconArrowRight} from '@tabler/icons';
import { useTranslation } from "react-i18next";


const NextBackButton = ({isSendButton, switchScreen}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const anchorRef = useRef(null);
    

    return (
        <Chip sx={{
            height: '48px',
            alignItems: 'center',
            borderRadius: '27px',
            transition: 'all .2s ease-in-out',
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.primary.light,
            '&[aria-controls="menu-list-grow"], &:hover': {
                borderColor: theme.palette.primary.main,
                background: `${theme.palette.primary.main}!important`,
                color: theme.palette.primary.light,
                '& svg': {
                    stroke: theme.palette.primary.light
                }
            },
            '& .MuiChip-label': {
                lineHeight: 0
            }
        }}
            label={
                <Stack direction="row" sx={{ p: 2 }}>
                    <Typography variant="subtitle1" color='inherit' >
                        {isSendButton ? t("Send") : t("Back")}
                    </Typography>
                    <Box sx={{ p: .5 }} />
                    {
                        isSendButton ? 
                        <IconArrowRight stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />
                        :
                        <IconArrowLeft stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />
                    }
                    
                </Stack>}
            variant="outlined"
            ref={anchorRef}
            onClick={() => switchScreen()}
            color="primary"
        />
    );
}

export default NextBackButton;