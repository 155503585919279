import { lazy } from 'react'; 

// project imports
import Loadable from '../ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout'; 
import SignInToContinue, {action as signInAction} from 'views/pages/authentication/authentication3/SignInToContinue';
 
 
// ==============================|| AUTHENTICATION ROUTING ||============================== //

// const AuthenticationRoutes = {
//   path: '/',
//   element: <MinimalLayout />,
//   children: [
//     { 
//       path: '/signintocontinue',
//       element: <SignInToContinue />,      
//       // action: signInAction
//     }
//   ]
// };

const AuthenticationRoutes = {
  path: '/',  
  element: <SignInToContinue />,  
};

export default AuthenticationRoutes;
