import { useTheme } from "@emotion/react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import LogoNav from "ui-component/LogoNav";
import { fwLightColor } from "views/utilities/utility";
import { useTranslation } from "react-i18next";


const PleaseWaitProgress = () => {
  const theme = useTheme()
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ width: 1, height: "70vh" }}
    >
      <Stack
        direction="column"
        alignItems={"center"}
        spacing={2}
        justifyContent="center"
      >
        <LogoNav width={140}/>
        
        <CircularProgress style={{color: fwLightColor}} />

        <Typography variant="caption">{t("Please-wait")}</Typography>
      </Stack>
    </Stack>
  );
};

export default PleaseWaitProgress;
