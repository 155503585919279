import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports 
import { MENU_OPEN } from 'store/actions';
import LogoNav from 'ui-component/LogoNav';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    dispatch({ type: MENU_OPEN, id: defaultId });
    navigate('/');
  }
  return (
    <ButtonBase disableRipple onClick={() => handleClick()} component={Link} to={'/'}>
      <LogoNav  width={140} />
    </ButtonBase>
  );
};

export default LogoSection;
