import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogContentText, Stack, Typography } from '@mui/material';
import { CheckCircleOutlineRounded, WarningOutlined, WarningRounded } from "@mui/icons-material";

const OkDialog = ({ title, message, isSuccess, open, handleClose }) => {
    return (
        <React.Fragment>
            <Dialog
                open={open}
                //onClose={handleCloseDialog} //comment it to disable outside close of dialog
                maxWidth='sm'
                fullWidth='true'

            >
                <DialogTitle>
                    <Stack direction={"row"} spacing={1}>
                        {
                            isSuccess &&
                            <CheckCircleOutlineRounded color="success" />
                        }

                        {
                            !isSuccess &&
                            <WarningRounded color="warning" />
                        }

                        <Typography variant="h3" color="text.primary">
                            {title}
                        </Typography>
                    </Stack>

                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Ok</Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    );
}

export default OkDialog;