import { AppContext } from "context/AppContext";
import {  useContext, useState } from "react";
import {
  MapContainer,
  Marker, 
  TileLayer,
  useMapEvent,
} from "react-leaflet";

const MapComponent = ({mapLocation}) => {
  const ctx = useContext(AppContext);
  const [userLocation, setUserLocation] = useState({
    latitude: ctx.latitude ?? 0,
    longitude: ctx.longitude ?? 0,
    accuracy: ctx.accuracy ?? 0,
    alt: 0
  });

  function LocationMarker({handleUserLocation}) {
    const [position, setPosition] = useState({
      lat: userLocation.latitude,
      lng: userLocation.longitude,
    });
    const map = useMapEvent({
      click(e) {
        setPosition(e.latlng);
        console.log(e.latlng)

        handleUserLocation({
            latitude: e.latlng.lat ?? 0,
            longitude: e.latlng.lng ?? 0,
            accuracy: 0,
            alt: e.originalEvent.altitudeAngle,
          });
        map.flyTo(e.latlng, map.getZoom());       
      },
    });

    
    return position === null ? null : (
      <Marker position={position}>
        {/* show popup on marker */}
        {/* <Popup>You are here</Popup> */}
      </Marker>
    );
  }

  const handleUpdateUserLocation = (location) => {
    setUserLocation(location)
    mapLocation(location)
  }

  return (
    <MapContainer
      style={{
        width: "100%",
        height: "50vh",
        zIndex: "1",
        borderRadius: "12px",
      }}
      center={{
        lat: userLocation.latitude,
        lng: userLocation.longitude,
      }}
      zoom={13}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LocationMarker handleUserLocation={handleUpdateUserLocation}/>
    </MapContainer>
  );
};

export default MapComponent;
