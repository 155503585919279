import React from 'react';

// material-ui
import { Box, Grid, Tab} from '@mui/material';
import { useState } from 'react';
import CustomCardWrapper from 'views/events/CustomCardWrapper'; 
import EventsWrapper from 'views/events/EventsWarpper';
import ProfileDetails from './ProfileDetails';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

import Skills from './skills/Skills';
import LocationDetails from './location/LocationDetails';

import { useTranslation } from "react-i18next";


const Settings = () => {
    const [value, setValue] = useState('1');    
    const { t } = useTranslation();
    
    const handleChange = (event, newValue) => { 
        setValue(newValue);
    }; 

    return (
        <EventsWrapper>
            <Grid container direction="column" justifyContent="space-evenly">
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" >
                        <Grid item sx={{
                            m: { xs: 1, sm: 3 }, mb: 0,
                            minWidth: { xs: 100, sm: 500, md: 600, lg: 600, xl: 700 }
                        }}>
                            <CustomCardWrapper header={'Settings'} icon={'setting'} >
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="Settings Tabs" centered>
                                            <Tab label={t("Profile-Details")} value="1" />
                                            <Tab label={t("Skills")} value="2" />
                                            <Tab label={t("Location-Details")} value="3" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <ProfileDetails />
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <Skills />
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <LocationDetails />
                                    </TabPanel>
                                </TabContext>
                            </CustomCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </EventsWrapper>
    );
}

export default Settings;
