import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

// ==============================|| CUSTOM SUB CARD ||============================== //

const SubCardSkill = forwardRef(({ children, content, contentClass, darkTitle, secondary, sx = {}, contentSX = {}, title, ...others }, ref) => {
  const theme = useTheme();

  return (
    <Card          
      ref={ref}
      sx={{ 
        marginBottom:2,
        backgroundColor: '#f7fbff',
        border: '1px solid', 
        borderColor: theme.palette.primary.light,
        ':hover': {
          boxShadow: '0 2px 14px #DEEFFF'          
          // boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)'          
        },
        ...sx
      }}
      {...others}
    >
      {/* card header and action */}
      {/* {!darkTitle && title && <CardHeader sx={{ p: 2.5 }} title={<Typography variant="h5">{title}</Typography>} action={secondary} />}
      {darkTitle && title && <CardHeader sx={{ p: 2.5 }} title={<Typography variant="h4">{title}</Typography>} action={secondary} />} */}

      {/* content & header divider */}
      {/* {title && (
        <Divider
          sx={{
            opacity: 1,
            borderColor: theme.palette.primary.light
          }}
        />
      )} */}

      {/* card content */}
      {content && (
        <CardContent key="CCard" sx={{ p: 0, '&:last-child': { pb: 0 }, ...contentSX}} className={contentClass || ''}>
          {children}
        </CardContent>
      )}
      {!content && children}
    </Card>
  );
});

SubCardSkill.propTypes = {
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  sx: PropTypes.object,
  contentSX: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

SubCardSkill.defaultProps = {
  content: true
};

export default SubCardSkill;
