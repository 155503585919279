import { Description } from "@mui/icons-material";
import SkillDetail from "./components/SkillDetail";
import { useTranslation } from "react-i18next";

const { Grid, Typography, Stack } = require("@mui/material");
const { AppContext } = require("context/AppContext");
const { useContext } = require("react");

const Skills = () => {
  const { t } = useTranslation();
  var ctx = useContext(AppContext);
  var skills = undefined;
  if (ctx.skills) {
    if (ctx.skills.length > 0) {
      skills = ctx.skills;
    }
  }
 

  return (
    <Grid>
      {skills &&
        skills.map((skill) => <SkillDetail key={skill.id} data={skill} />)}
      {!skills && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: 1, height: "20vh" }}
        >
          <Stack
            direction="column"
            alignItems={"center"}
            spacing={2}
            justifyContent="center"
            sx={{ pt: 4 }}
          >
            <Description style={{ color: "lightgrey" }} />
            <Typography variant="caption">
            {t("No-skills-are-currently-available")}.
            </Typography>
          </Stack>
        </Stack>
      )}
    </Grid>
  );
};

export default Skills;
