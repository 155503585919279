import { Link, useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { 
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
import AuthWrapper from "../AuthWrapper";
import AuthCardWrapper from "../AuthCardWrapper";
import Logo from "ui-component/Logo";
import AuthRequestNewAccount from "../auth-forms/AuthRequestNewAccount";
import AuthFooter from "ui-component/cards/AuthFooter"; 
import { useContext, useEffect, useState } from "react";
import { app } from "@microsoft/teams-js";
import { AppContext } from "context/AppContext";
import { isStringEmpty } from "views/utilities/utility";
import {
  performSignInUsingTeamsId, 
  sessionLogin, 
  sessionStatus,
} from "apis/httpcom";
import PleaseWaitProgress from "views/common-components/PleaseWaitProgress";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// ===============================|| AUTH3 - REGISTER ||=============================== //

const SignInToContinue = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const ctx = useContext(AppContext);
  const [hasInitialized, setHasInitialized] = useState(false);
  const [progress, setProgress] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  

  useEffect(() => {
    async function getLoggedInUserInfo() {
      try {  
        //check if app has initialized
        const isInitialied = app.isInitialized();

        if (isInitialied) {
          setHasInitialized(isInitialied);

          //get appContext
          const appCtx = await app.getContext();
          console.log('appCtx', appCtx);

          if (appCtx) {
            const userEmail = appCtx.user?.userPrincipalName;
            if (!isStringEmpty(userEmail)) {
              ctx.updateUserEmail(userEmail);

              if (!isStringEmpty(userEmail)) {
                //get session login status
                const resSession = await sessionStatus(btoa(userEmail));

                //if session exists then perform automatic login
                if (resSession) {
                  // try {
                  var response = await performSignInUsingTeamsId(userEmail);
                  if (response.status === 200) {
                    var data = await response.json();
                    if (data && data.level) {
                      if (data.level === 1) {
                        
                        //set session
                        await sessionLogin({
                          email: btoa(ctx.useremail),
                          at: data.returnValue.authToken,
                        });

                        //update response data
                        ctx.updateProfileData(data);

                        //navigate to events screen
                        navigate("/main/ems");
                        return;
                      }
                    }
                  }
                } 
                setProgress(false);
              } else {
                setProgress(false);
              }
            }
          } else {
            setProgress(false); 
          }
        } else {
          setProgress(false); 
        }
      } catch (error) {
        console.log("ex", error);
        setProgress(false);
        showFailedToLoginMsg();
      } 
    }

    getLoggedInUserInfo();
  }, [navigate]);

  const showFailedToLoginMsg = () => {
    toast.error(
      <>
        <Stack>
          <Typography variant="subtitle1">{t("Unable-to-login")}!</Typography>
          <Typography variant="caption">
            {t('Unable-to-login-please-try-again-after-some-time')}
          </Typography>
        </Stack>
      </>,
      {
        position: "top-right",
      }
    );
  };

  return (
    <AuthWrapper>
      {progress ? (
        <PleaseWaitProgress />
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          sx={{ minHeight: "100vh" }}
        >
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: "calc(100vh - 68px)" }}
            >
              <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                <AuthCardWrapper>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item sx={{ mb: 3 }}>
                      <Link to="#">
                        <Logo />
                      </Link>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction={matchDownSM ? "column-reverse" : "row"}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Stack
                              direction={"column"}
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Typography
                                gutterBottom
                                variant={matchDownSM ? "h5" : "h5"}
                              >
                                {t("Login-to")}
                              </Typography>
                              <Typography
                                color={theme.palette.ems.main}
                                gutterBottom
                                variant={matchDownSM ? "h3" : "h2"}
                              >
                                {t("BHV-Alert")}
                              </Typography>
                            </Stack>
                            {hasInitialized && (
                              <Typography
                                variant="subtitle2"
                                textAlign={matchDownSM ? "center" : "inherit"}
                              >
                                {t("Please-Select-Continue")}
                              </Typography>
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <AuthRequestNewAccount />
                    </Grid>
                  </Grid>
                </AuthCardWrapper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
            <AuthFooter />
          </Grid>
        </Grid>
      )}
    </AuthWrapper>
  );
};

export default SignInToContinue;
