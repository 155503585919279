import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import Routes from "./routes";

// defaultTheme
import themes from "./themes";

// project imports
import NavigationScroll from "./layout/NavigationScroll";

import "react-toastify/dist/ReactToastify.css";

import AppContextProvider from "./context/AppContext";
import config from "./apis/config";
import React, { useEffect, useState } from "react";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { app, teamTabs } from "@microsoft/teams-js";
import PleaseWaitProgress from "views/common-components/PleaseWaitProgress";
import "./localization/i18next";
import { isStringEmpty, supportLanguages } from "views/utilities/utility";
import { useTranslation } from "react-i18next";
 

const App = () => {
  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config?.initiateLoginEndpoint ?? "default-value",
      clientId: config?.clientId ?? "default-value",
    });

  const customization = useSelector((state) => state.customization);
  const [progress, setProgress] = useState(true);
  const { t, i18n } = useTranslation();
 
  useEffect(() => {
    async function initializeSDK() {
      try {
        console.log("initializing sdk");

        //initialize teams App environment
        await app.initialize();
        setProgress(false);

        //set app language
        const appCtx = await app.getContext();
        console.log("teamsTab", teamTabs);

        if (appCtx) {
          const locale = appCtx.app.locale.toLowerCase();
          if (!isStringEmpty(locale)) {
            const selectedLang = supportLanguages.find((item) =>
              locale.includes(item)
            );
            if (selectedLang) {
              i18n.changeLanguage(selectedLang);
            }
          }
        }
      } catch (error) {
        console.log("ex", error);
        setProgress(false);
      }
    }

    initializeSDK();
  }, []);

  return (
    //We are Keeping these values blank so that we can take default values from context file
    <AppContextProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            {progress ? <PleaseWaitProgress /> : <Routes />}
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </AppContextProvider>
  );
};

export default App;
