import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { SERVER_FW_Teams } from 'apis/httpcom';
import React, { createContext, useState }  from 'react';
import { useNavigate } from 'react-router';

export const AppContext = createContext({
    useremail: 'gaurav@findwhere.com',
    status: false,
    authToken: '',

    profileFirstName: '',
    profileLastName: '',
    profileTimeZone: '',
        
    profileImage: '',    
    profileLocation: '',
    profileLanguage: '',
    geofenc: '',
    
    events: [],
    skills: [],
    availableLanguages: [],

    latitude: 0,
    longitude: 0,

    updateEvents: () => {}, 
    updateSkills: () => {},      
    updateAvailableLanguages: () => {},

    updateUserEmail: () => {},
    updateProfileFirstName: () => {},
    updateProfileLastName: () => {},
    updateTimeZone: () => {},    
    updateProfileImage: () => {},
    updateProfileLocation: () => {},
    updateAuthToken: () => {},
    updateLatitudeLongitude: () => {},
    updateGeoFenc: () => {},
    updateProfileData: () => {},

    createHubConnection: () => {},
    stopConnection: () => {},
});

export default function AppContextProvider ({children}) { 
    const [useremail, setUserEmail] = useState('');
    const [status, setstatus] = useState(true);
    const [profileFirstName, setProfileFirstName] = useState('');
    const [profileLastName, setProfileLastName] = useState('');
    const [profileTimeZone, setProfileTimeZone] = useState('');    
    const [profileImage, setProfileImage] = useState('');
    const [profileLocation, setProfileLocation] = useState('');
    const [profileLanguage, setProfileLanguage] = useState('en');
    
    const [events, setEvents] = useState([]);
    const [skills, setSkills] = useState([]);
    const [availableLanguages, setAvailableLanguages] = useState([]);

    const [authToken, setAuthToken] = useState('');
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [geofenc, setGeoFenc] = useState('');

    const navigate = useNavigate();

    let hubConnection = undefined;

    const handleUpdateEvents = (values) => {
        //events.push(...values); 
        setEvents(values);
    }

    const handleUpdateSkills = (values) => {
        //skills.push(...values); 
        setSkills(values);
    }

    const handleUpdateAvailableLanguages = (value) => {
        setAvailableLanguages(value); 
    }

    const handleUpdateProfileLanguage = (value) => {
        setProfileLanguage(value); 
    }
 
    const handleUpdateProfileFirstName = (value) => {
        setProfileFirstName(value); 
    }

    const handleUpdateProfileLastName = (value) => {
        setProfileLastName(value); 
    }

    const handleUpdateProfileEmail = (value) => {
        setUserEmail(value); 
    }

    const handleUpdateTimeZone = (value) => {
        setProfileTimeZone(value); 
    }
   
    const handleUpdateProfileImage = (value) => {
        setProfileImage(value); 
    }

    const handleUpdateLocation = (value) => {
        setProfileLocation(value); 
    }

    const handleUpdateAuthToken = (value) => {
        setAuthToken(value); 
    }

    const handleLatituteLongtitude = (lat, lng) => {
        setLatitude(lat);
        setLongitude(lng);
    }

    const handleGeoFenc = (value) => {
        setGeoFenc(value);
    }

    const handleProfileData = (data) => {
      handleUpdateProfileFirstName(data.returnValue.profileFirstName);
      handleUpdateProfileLanguage(data.returnValue.profileLastName);
      handleUpdateProfileLanguage(data.returnValue.profileLanguage);
      handleUpdateProfileImage(data.returnValue.profileImage);
      handleUpdateLocation(data.returnValue.profileLocation);
      handleUpdateAuthToken(data.returnValue.authToken);
      handleUpdateTimeZone(data.returnValue.profileTimeZone);

      handleUpdateEvents(data.returnValue.events);
      handleUpdateSkills(data.returnValue.skills);
      handleUpdateAvailableLanguages(data.returnValue.availableLanguages);
      handleLatituteLongtitude(
        data.returnValue.latitude,
        data.returnValue.longitude
      );
    };

    const handleCreateHubConnection = (value) => {
        console.log('handleCreateHubConnection')
      const activityId = value

      if (activityId) {
        hubConnection = new HubConnectionBuilder()
          .withUrl(SERVER_FW_Teams + '/activity?activityId=' + activityId, {
            accessTokenFactory: ''
          })         
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Information)
          .build()

        hubConnection.start().catch(error => console.log('Error establishing connection: ', error))

        hubConnection.on('perform-force-logout', data => {
          console.log('perform-force-logout', data)

          //goe back to main screen
          navigate("/")



          // runInAction(() => {
          //     comments.forEach(comment => {
          //         comment.createdAt = new Date(comment.createdAt);
          //     });
          //     this.comments = comments;
          // });
        }) 
      }
    }

    const handleStopConnection = (value) => {
      try {
        console.log("handleStopConnection");
        hubConnection
          ?.stop()
          .catch((error) => console.log("Error stopping connection: ", error));
      } catch (error) {
        console.log(error);
      }
    };

    const ctxValue = {
        useremail: useremail,
        status: status,
        authToken: authToken,
    
        profileFirstName: profileFirstName,
        profileLastName: profileLastName,
        profileTimeZone: profileTimeZone,
        
        profileImage: profileImage,
        profileLocation: profileLocation,
        profileLanguage: profileLanguage,
        
        events: events,
        skills: skills,   
        availableLanguages: availableLanguages,   
        
        latitude: latitude,
        longitude: longitude,
        geofenc: geofenc,

        updateEvents: handleUpdateEvents, 
        updateSkills: handleUpdateSkills,
        updateAvailableLanguages: handleUpdateAvailableLanguages,

        updateUserEmail: handleUpdateProfileEmail,
        updateProfileFirstName: handleUpdateProfileFirstName,
        updateProfileLastName: handleUpdateProfileLastName,        
        updateProfileImage: handleUpdateProfileImage,
        updateProfileLanguage: handleUpdateProfileLanguage,
        updateProfileLocation: handleUpdateLocation,
        updateAuthToken: handleUpdateAuthToken,
        updateTimeZone: handleUpdateTimeZone,
        updateLatitudeLongitude: handleLatituteLongtitude,
        updateGeoFenc: handleGeoFenc,

        updateProfileData: handleProfileData,

        createHubConnection: handleCreateHubConnection,
        stopConnection: handleStopConnection 
    };
     
    return <AppContext.Provider value={ctxValue}>
        {children}
    </AppContext.Provider>
}