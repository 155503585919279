
// project imports 
import MinimalLayout from 'layout/MinimalLayout'; 
import LocationDetails from 'views/settings/location/LocationDetails';
 
 
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const MapRoute = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/map',
      element: <LocationDetails />,
    }
  ]
};

export default MapRoute;
