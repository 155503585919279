import moment from 'moment';
import { json } from 'react-router-dom';
import { getFormattedDate } from 'views/utilities/utility';

// const SERVER = "https://staging2.findwhere.com/ems-ws/service";
// //export const SERVER_FW_Teams = "http://localhost:3978";
// export const SERVER_FW_Teams = "https://findwhere-bot20240715103023.azurewebsites.net";

const SERVER = process.env.REACT_APP_BASE_URL;
export const SERVER_FW_Teams = process.env.REACT_APP_BASE_URL_FW_TEAMS;


export async function performSignInUsingTeamsId(useremail) {
  const requestBody = {
    email: btoa(useremail),
    grantType: 'password',
    version: "1.8.0.0"
  }

  const response = fetchResponse(SERVER +'/liT', requestBody);  
  printResponse(response);  

  if (!(await response).ok) {
    throw json({ message: 'Could not authenticate user.' }, { level: 500 });
  }

  return response;
}

export async function requestForNewAccount(email, firstName, lastName, message) { 
  const requestBody = {
    email: email ? btoa(email) : '',
    firstName: firstName ? btoa(firstName) : '',
    lastName: lastName ? btoa(lastName) : '',
    message: message ? btoa(message) : ''
  }

  const response = fetchResponse(SERVER +'/naR', requestBody);
  printResponse(response); 

  if (!(await response).ok) {
    throw json({ message: 'Could not authenticate user.' }, { level: 500 });
  } 

  return response;
}

export async function sendEvent(authToken, eventId, message, pictureString, lat, lng) {
  //console.log('sendEvent', authToken, eventId, message);

  const eventData = {
    authToken: authToken,
    eventTypeId: eventId,
    comments: message !== "" ? message : "",
    pc_name: "",
    mac: "",
    machineType: 3,
    latitude: lat,
    longitude: lng,
    altitude: 0,
    accuracy: 0,
    fixStatus: 6,
    fixTime: getFormattedDate(new Date()),
    wifiSSID: "",
    wifiMAC: "",
    wifiStrenght: 0,
    bleName: "",
    bleAddress: "",
    bleRssi: "",
    pictureString: pictureString,
  };

  const response = fetchResponse(SERVER +'/tAT', eventData);
  printResponse(response);

  if (!(await response).ok) {
    throw json({ message: 'request failed' }, { level: 500 }); 
  }

  return response;
}


export const updateCertificateDetails = async (authToken, skillId, certDt, certValidDt, mediaType, file) => { 
  const requestBody = {
    skillId: skillId,
    authToken: authToken,
    certificateDate: certDt,
    validUntilDate: certValidDt,
    mediaType: mediaType,
    file: file
  };

  console.log('request', requestBody);
  const response = fetchResponse(SERVER +'/uSt', requestBody);
  printResponse(response);

  if (!(await response).ok) {
    throw json({ message: 'Could not authenticate user.' }, { level: 500 });
  }

  return response;
}

export const updateProfileDetails = async (authToken, firstName, lastName, language, pictureString) => { 
  const requestBody = {
    authToken: authToken,
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    language: language ?? '',
    pictureString: pictureString ?? ''
  };

  //console.log('request', requestBody); 

  const response = fetchResponse(SERVER +'/uPT', requestBody);
  printResponse(response); 

  if (!(await response).ok) {
    throw json({ message: 'Could not authenticate user.' }, { level: 500 });
  } 

  return response;
}

export const updateLocationDetails = async (authToken, lat, lng, accuracy, txtLocationDescription) => { 
  
  const requestBody = {
    authToken: authToken,
    pc_name : '',
    mac :  '',
    machineType : 1,
    latitude  : lat,
    longitude : lng,
    altitude : 0,
    accuracy : accuracy, 
    fixStatus : 1,
    fixTime : moment(new Date()).format('DD-MM-YYYY HH:mm'),
    wifiSSID : "",
    wifiMAC : "",
    wifiStrenght : 0, 
    bleName : '',
    bleAddress : '',
    bleRssi : '',
    location : txtLocationDescription
  };

  //console.log('request', requestBody); 

  const response = fetchResponse(SERVER +'/ulT', requestBody);
  printResponse(response);
 

  if (!(await response).ok) {
    throw json({ message: 'failed to update location.' }, { level: 500 });
  } 

  return response;
}


export const fwLogout = async (authCode) => {
  const requestBody = authCode; 

  const response = await fetchPost(SERVER +'/loT', requestBody);
  printResponse(response);
  
  if (!(await response).ok) {
    return false;
  } 

  return true;
};

export const sessionLogin = async (params) => { 
  const requestBody = params;

  console.log('sessionLogin request', requestBody); 

  const response = fetchPost(SERVER_FW_Teams +'/api/session/slg', requestBody);
  printResponse(response); 

  if (!(await response).ok) {
    return false;
  } 

  return true;
}

export const sessionStatus = async (useremail) => {
  const requestBody = useremail;

  //console.log("sessionStatus request", requestBody);

  const response = await fetchPost(SERVER_FW_Teams + "/api/session/sst", requestBody);
  printResponse(response);
  
  if (response.status === 200) {
    var data = await response.text();
    //console.log('response data', data);
    if (data === "1") {
      return true;
    }
  }

  return false;
};


export const sessionLogout = async (useremail) => {
  const requestBody = useremail; 

  const response = await fetchPost(SERVER_FW_Teams + "/api/session/slo", requestBody);
  printResponse('sessionlogout', response);
  
  if (!(await response).ok) {
    return false;
  } 

  return true;
};

const fetchResponse = (url, requestBody) => {
  const response = fetch(url, {
    method: 'POST',
    header: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody)
  });
  return response; 
}

const fetchPost = (url, requestBody) => {
  const response = fetch(url, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(requestBody)
  });
  return response; 
}

const printResponse = (response) => {
  // console.log('Response starts here');
  // console.log(response);
  // console.log('Response ends here');
}