import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Typography, Stack, Button } from "@mui/material";

import {
  AddAPhotoOutlined,
  AttachFileOutlined,
  LocalSee,
} from "@mui/icons-material";
import { fwLightColor } from "views/utilities/utility";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as microsoftTeams from "@microsoft/teams-js";
import { any } from "prop-types";
import { useEffect, useState } from "react";
import {
  getBase64OfFile,
  isStringEmpty,
  isValidFile,
} from "views/utilities/utility";

export default function ProfileImageButton({ handleSelectedImageBase64 }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [imageFile, setImageFile] = useState({
    default: "Select a file",
    filepath: "",
    filename: "",
    fileobj: any,
    hasCameraImage: false,
    cameraImageBas64: "",
  });

  useEffect(() => {
    // initializing microsoft teams sdk
    microsoftTeams.app.initialize();
  });

  const handleFileSelect = async (e) => {
    handleClose()

    console.log(e.target.value);
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    const { name } = file;

    setImageFile({
      filename: name,
      filepath: e.target.value,
      fileobj: file,
    });

    if (isValidFile(e.target.value)) {
      //obtain base64
      var imageBase64 = await getBase64OfFile(file);
      if (!isStringEmpty(imageBase64)) {
        imageBase64 = imageBase64.split(",")[1];

        handleSelectedImageBase64(imageBase64);
        showSuccessMsg();
      }
    }
  };

  const handleCaptureCamera = async () => {
    const hasPermission = await microsoftTeams.media.hasPermission();
    console.log("Camera Permission Status: ", hasPermission);

    //check camera permission status
    if (!hasPermission) {
      toast.info(
        <>
          <Stack>
            <Typography variant="subtitle1">No Camera Access!</Typography>
            <Typography variant="caption">
              {
                "To take picture for your profile, allow us to use your camera in App Settings > Media and toggle ON."
              }
            </Typography>
          </Stack>
        </>,
        {
          position: "top-right",
        }
      );
      return;
    } else {
      var listOfSelectedImages = new Array();
      var imageProp = {
        sources: [
          microsoftTeams.media.Source.Camera,
          microsoftTeams.media.Source.Gallery,
        ],
        startMode: microsoftTeams.media.CameraStartMode.Photo,
        ink: false,
        cameraSwitcher: false,
        textSticker: false,
        enableFilter: true,
      };
      var mediaInput = {
        mediaType: microsoftTeams.media.MediaType.Image,
        maxMediaCount: 1,
        imageProps: imageProp,
      };
      microsoftTeams.media.selectMedia(
        mediaInput,
        function (error, attachments) {
          console.log("selectedImage", error, attachments);
          if (error) {
            if (error.message) {
              alert(" ErrorCode: " + error.errorCode + error.message);
            } else {
              alert(" ErrorCode: " + error.errorCode);
            }
          }
          if (attachments) {
            if (attachments.length > 0) {
              let selectedImage = attachments[0];
              console.log(selectedImage);
              setImageFile({
                hasCameraImage: true,
                cameraImageBas64: selectedImage.preview,
              });

              handleSelectedImageBase64(selectedImage.preview);
              showSuccessMsg();
            }
          }
        }
      );
    }
  };

  const showSuccessMsg = () => {
    toast.success(
      <>
        <Stack>
          <Typography variant="caption">
            {"Photo selected successfully."}
          </Typography>
        </Stack>
      </>,
      {
        position: "top-right",
      }
    );
  };

  //--------
//   const [anchorEl, setAnchorEl] = useState(null);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleFileSelect = (event) => {
//     const file = event.target.files[0];
//     console.log(file);
//     handleClose();
//   };

  return (
    // <div>
    //   <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
    //     Open Menu
    //   </Button>
    //   <Menu
    //     id="simple-menu"
    //     anchorEl={anchorEl}
         
    //     open={Boolean(anchorEl)}
    //     onClose={handleClose}
    //   >
    //     <MenuItem>
    //       <input
    //         type="file"
    //         style={{ display: 'none' }}
    //         id="file-input"
    //         onChange={handleFileSelect}
    //       />
    //       <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
    //         Select File
    //       </label>
    //     </MenuItem>
    //   </Menu>
    // </div>
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Change Photo">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
                background: fwLightColor,
                border: "4px solid white",
              }}
            >
              <LocalSee sx={{ padding: "4", color: "white" }} />
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>

         {/* <Menu
        id="account-menu"
        anchorEl={anchorEl}
         
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <input
            type="file"
            style={{ display: 'none' }}
            id="file-input"
            onChange={handleFileSelect}
          />
          <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
            Select File
          </label>
        </MenuItem>
       </Menu> */}
      
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
         
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
        >
          <ListItemIcon>
            <AttachFileOutlined fontSize="small" />
          </ListItemIcon>
          <input
            type="file"
            style={{ display: "none" }}
            id="file-input"
            onChange={handleFileSelect}
          />
          <label htmlFor="file-input" style={{ cursor: "pointer" }}>
            Select from disk
          </label>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleCaptureCamera();
            handleClose();
          }}
        >
          <ListItemIcon>
            <AddAPhotoOutlined fontSize="small" />
          </ListItemIcon>
          Capture via Webcam
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
