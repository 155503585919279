import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography, Avatar } from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Settings } from '@mui/icons-material';

// constant
const headerSX = {
  '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = '',
      contentSX = {},
      darkTitle,
      action,
      shadow,
      sx = {},
      title,
      subheader,
      icon,
      iconUrl,
      headerColor,
      whiteForegroundColor,
      ...others
    },
    ref
  ) => {
    const theme = useTheme(); 
    var iconType = <NotificationsActiveIcon sx={{fontSize:30}}/>;
 
    if(icon === 'setting'){      
      iconType = <Settings sx={{fontSize: 30}}/>
    }

    if(iconUrl){
      iconType = <img src={iconUrl} alt="Alert" width={36} /> 
    }

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? "1px solid" : "none",
          borderColor: theme.palette.primary[200] + 25,
          ":hover": {
            boxShadow: boxShadow
              ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)"
              : "inherit",
          },
          ...sx,
        }}
      >
        {/* card header and action */}
        {title && (
          <CardHeader
            sx={{
              backgroundColor: headerColor ? headerColor : "inherit",
              color: whiteForegroundColor ? whiteForegroundColor : "inherit",
            }}
            avatar={iconType}
            title={
              <Typography  
                  noWrap={false}                
                  fontSize={'20px'}
                  sx={{
                    pr:5,
                    fontWeight: 400,
                    color: whiteForegroundColor
                      ? whiteForegroundColor
                      : "inherit",
                  }}
                >
                  {title}
                </Typography>
            }
            subheader={
              <Typography
                variant="subtitle2"
                sx={{
                  color: whiteForegroundColor
                    ? whiteForegroundColor
                    : "inherit",
                }}
              >
                {subheader}
              </Typography>
            }
            action={action}
          />
        )}

        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

MainCard.propTypes = {
  border: PropTypes.bool,
  boxShadow: PropTypes.bool,
  children: PropTypes.node,
  content: PropTypes.bool,
  contentClass: PropTypes.string,
  contentSX: PropTypes.object,
  darkTitle: PropTypes.bool,
  secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  shadow: PropTypes.string,
  sx: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
};

export default MainCard;
