import { Button, Typography, Stack } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as microsoftTeams from "@microsoft/teams-js";
import { any } from "prop-types";
import { useEffect, useState } from "react";
import {
  getBase64OfFile,
  isStringEmpty,
  isValidFile,
} from "views/utilities/utility";

import { useTranslation } from "react-i18next";


const SelectOrCapturePhoto = ({ props, handleSelectedImageBase64 }) => {
  const { t } = useTranslation();
  const [imageFile, setImageFile] = useState({
    default: "Select a file",
    filepath: "",
    filename: "",
    fileobj: any,
    hasCameraImage: false,
    cameraImageBas64: "",
  });

  useEffect(() => {
    // initializing microsoft teams sdk
    microsoftTeams.app.initialize();
  });

  const handleFileSelect = async (e) => {
    console.log(e.target.value);
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    const { name } = file;

    setImageFile({
      filename: name,
      filepath: e.target.value,
      fileobj: file,
    });

    if (isValidFile(e.target.value)) {
      //obtain base64
      var imageBase64 = await getBase64OfFile(file);
      if (!isStringEmpty(imageBase64)) {
        imageBase64 = imageBase64.split(",")[1];

        handleSelectedImageBase64(imageBase64);
        showSuccessMsg();
      }
    }
  };

  const handleCaptureCamera = async () => {
    const hasPermission = await microsoftTeams.media.hasPermission(); 

    //check camera permission status
    if (!hasPermission) {
      toast.info(
        <>
          <Stack>
            <Typography variant="subtitle1">{t('No-Camera-Access')}!</Typography>
            <Typography variant="caption">
              {
                t("To take picture for your profile, allow us to use your camera in App Settings > Media and toggle ON.")
              }
            </Typography>
          </Stack>
        </>,
        {
          position: "top-right",
        }
      );
      return;
    } else {
      var listOfSelectedImages = new Array();
      var imageProp = {
        sources: [
          microsoftTeams.media.Source.Camera,
          microsoftTeams.media.Source.Gallery,
        ],
        startMode: microsoftTeams.media.CameraStartMode.Photo,
        ink: false,
        cameraSwitcher: false,
        textSticker: false,
        enableFilter: true,
      };
      var mediaInput = {
        mediaType: microsoftTeams.media.MediaType.Image,
        maxMediaCount: 1,
        imageProps: imageProp,
      };
      microsoftTeams.media.selectMedia(
        mediaInput,
        function (error, attachments) {
          console.log("selectedImage", error, attachments);
          if (error) {
            if (error.message) {
              console.log(error.message)
              //alert(" ErrorCode: " + error.errorCode + error.message);
            } else {
              console.log(error.errorCode)
              //alert(" ErrorCode: " + error.errorCode);
            }
          }
          if (attachments) {
            if (attachments.length > 0) {
              let selectedImage = attachments[0];
              console.log(selectedImage);
              setImageFile({
                hasCameraImage: true,
                cameraImageBas64: selectedImage.preview,
              });

              handleSelectedImageBase64(selectedImage.preview);
              showSuccessMsg();
            }
          }
        }
      );
    }
  };

  const showSuccessMsg = () => {
    toast.success(
      <>
        <Stack>
          <Typography variant="caption">
            {t("Photo-selected-successfully")}
          </Typography>
        </Stack>
      </>,
      {
        position: "top-right",
      }
    );
  };

  return (
    <Stack direction={"row"} spacing={2}>
      <Button
        fullWidth
        component="label"
        variant="outlined"
        sx={{ marginRight: "1rem", textTransform: "none" }}
      >
        {imageFile.filename && imageFile.filename}
        {!imageFile.filename && <>{t('Select-from-disk')}</>}
        <input
          type="file"
          accept=".jpg, .jpeg, .png"
          hidden
          onChange={handleFileSelect}
        />
      </Button>

      <Button
        fullWidth
        component="label"
        variant="outlined"
        sx={{ marginRight: "1rem", textTransform: "none" }}
        onClick={handleCaptureCamera}
      >
        {t('Capture-via-Webcam')}
      </Button>
    </Stack>
  );
};

export default SelectOrCapturePhoto;
