import PropTypes from "prop-types";

// material-ui
import { Grid, Stack, Typography } from "@mui/material";
import SubCard from "ui-component/cards/SubCard";
import EventBox from "./EventBox";

// project imports
import { gridSpacing } from "store/constant";
import EventsCardWrapper from "./CustomCardWrapper"; 
import { useContext } from "react";
import { AppContext } from "context/AppContext";
import { 
  Description, 
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

EventBox.propTypes = {
  bgcolor: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  dark: PropTypes.bool,
};

const Events = ({ switchScreen, updateSelectEvent }) => {
  const { t } = useTranslation();
  const ctx = useContext(AppContext);

  var EventsData = undefined;
  if (ctx.events) {
    if (ctx.events.length > 0) {
      EventsData = ctx.events;
    }
  } 
 

  const eventCountStatus = () => {
    var eventsCount = 0;
    if (EventsData) {
      eventsCount = EventsData.length;
    }
    return eventsCount; 
  };

  return (
    <Grid
      item
      sx={{
        m: { xs: 1, sm: 3 },
        mb: 0,
        minWidth: { xs: 100, sm: 500, md: 600, lg: 600, xl: 700 },
      }}
    >
      <EventsCardWrapper
        header={eventCountStatus() > 0 ? t("Events") : ""}
        subHeader={
          eventCountStatus() > 0 ? t("Select-from-the-following-events") : ""
        }
      >
        <Grid container spacing={gridSpacing}>
          <Grid container item xs={12} spacing={2}>
            {EventsData &&
              EventsData.map((event) => (
                <Grid
                  key={event.eventId}
                  item
                  xs={6}
                  sx={{ p: 0 }}
                  onClick={() => {
                    updateSelectEvent(event);
                    switchScreen();
                  }}
                >
                  <SubCard
                    sx={{
                      p: 0,
                      cursor: "pointer",
                      ":hover": {
                        boxShadow: 10,
                      },
                    }}
                  >
                    <EventBox data={event} title="primary.light" />
                  </SubCard>
                </Grid>
              ))}
            {!EventsData && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: 1, height: "20vh" }}
              >
                <Stack
                  direction="column"
                  alignItems={"center"}
                  spacing={2}
                  justifyContent="center"
                  sx={{ pt: 4 }}
                >
                  <Description style={{ color: "lightgrey" }} />
                  <Typography variant="caption">
                    {t('No-events-are-currently-available')}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Grid>
        </Grid>
      </EventsCardWrapper>
    </Grid>
  );
};

export default Events;
