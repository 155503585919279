// import React from "react";
// import { createRoot } from "react-dom/client";
// import App from "./components/App";
// import "./index.css";

// const container = document.getElementById("root");
// const root = createRoot(container);
// root.render(<App />);


//-----------------------
import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from './serviceWorker';
import App from './App';
import { store } from './store';

// style + assets
import './assets/scss/style.scss';
import config from './config';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
 

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <>
      <App />
      <ToastContainer />
      </>
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
