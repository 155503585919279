import React, { useContext, useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

// third party
import * as Yup from "yup";
import { useFormik } from "formik";

// project imports
import { updateLocationDetails } from "apis/httpcom";
import { AppContext } from "context/AppContext"; 
 
 
import imgEMS from "assets/images/ems_128.png";
import { LocationOn, MyLocationOutlined } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { Stack } from "@mui/system";
import { toast } from "react-toastify"; 
import { fwLightColor } from "views/utilities/utility";
import MapComponent from "./MapComponent";
import { useTranslation } from "react-i18next";

 
const LocationDetails = ({ ...others }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const ctx = useContext(AppContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
 
  const [userLocation, setUserLocation] = useState({
    latitude: ctx.latitude ?? 0,
    longitude: ctx.longitude ?? 0,
    accuracy: ctx.accuracy ?? 0,
    alt: 0
  });
   

  const handleSubmit = async (values) => { 
    setIsSubmitting(true);

    //send request
    const response = await updateLocationDetails(
      ctx.authToken,
      userLocation.latitude ?? 0,
      userLocation.longitude ?? 0,
      userLocation.accuracy ?? 0,
      values.additionalLocationInformation
    );

    let data = await response.json(); 

    if (data && data.level) {
      if (data.level === 1) {

        //update lat long to context
        ctx.updateLatitudeLongitude(userLocation.latitude, userLocation.longitude)


        //show success dialog msg
        toast.success(
          <>
            <Stack>
              <Typography variant="subtitle1">{t("Location Updated")}!</Typography>
              <Typography variant="caption">
              {t("Location-updated-successfully")}.
              </Typography>
            </Stack>
          </>,
          {
            position: "top-right",
          }
        );

        setIsSubmitting(false);
        return;
      }
    }

    toast.error(
      <>
        <Stack>
          <Typography variant="subtitle1">{t("Couldnt-update")}!</Typography>
          <Typography variant="caption">
            {t("Unable-to-update-location-please-try-again")}.
          </Typography>
        </Stack>
      </>,
      {
        position: "top-right",
      }
    );
    setIsSubmitting(false);
  };

  const defaultValues = {
    additionalLocationInformation: "",
  };

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Yup.object({
      additionalLocationInformation: Yup.string().max(100),
    }),
    onSubmit: handleSubmit,
  });

  const getUserLocation = async () => {
    setIsLoading(true);
    if (navigator.geolocation) {
      const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude, accuracy } = position.coords;
            if (latitude > 0 && longitude > 0) {
              setUserLocation({ latitude, longitude, accuracy });
              setIsLoading(false);
            } else {
              setIsLoading(false);
              failedToGetLocation();
            }
          },
          // if there was an error getting the users location
          (error) => {
            //console.error("Error getting user location:", error);
            setIsLoading(false);
            failedToGetLocation();
          }
        );
      });

      const { latitude, longitude, accuracy } = pos.coords;
      if (latitude > 0 && longitude > 0) {
        setUserLocation({ latitude, longitude, accuracy });
        setIsLoading(false);
        return true;
      } else {
        setIsLoading(false);
        failedToGetLocation();
        return false;
      }
    } else {
      setIsLoading(false);
      failedToGetLocation();
      return false;
    }
  };

  const failedToGetLocation = () => {
    toast.info(
      <>
        <Stack>
          <Typography variant="subtitle1">{t("Location Failed")}!</Typography>
          <Typography variant="caption">
            {t("Couldnt-fetch-location-details-please-try-again")}.
          </Typography>
        </Stack>
      </>,
      {
        position: "top-right",
      }
    );
  };
 
  useEffect(() => { 
    formik.setValues({
      additionalLocationInformation: ctx.profileLocation,
    });
  }, [ctx.profileLocation]);

  const obtainLocationData = () => {
    setIsLoading(true);
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        setIsLoading(false);
        if (result === undefined) {
          allowNotificationToAllowPermission();
          return;
        }
        if (result.state === "granted") {
          getUserLocation();
        } else {
          allowNotificationToAllowPermission();
        }
      });
  };

  const allowNotificationToAllowPermission = () => {
    toast.info(
      <>
        <Stack>
          <Typography variant="subtitle1">{t("No-Location-Access")}!</Typography>
          <Typography variant="caption">
            {t("To-obtain-location-data-allow-us-access-to-use-your-location")}.
          </Typography>
        </Stack>
      </>,
      {
        position: "top-right",
      }
    );
  }; 
  

  const handleSetLocation = () => {
    obtainLocationData();
  };

  const handleMapLocation = (location) => {
    setUserLocation(location);
    //console.log('location', location)
  }

  
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {userLocation.latitude === 0 && (
          <Grid sx={{ mt: 2, mb: 4 }}>
            <Stack direction={"column"} spacing={2} alignItems={"center"}>
              <LocationOn style={{ fontSize: 100, color: grey }} />
              <LoadingButton
                loading={isLoading}
                size="medium"
                variant="outlined"
                startIcon={<MyLocationOutlined />}
                onClick={handleSetLocation}
                sx={{ borderRadius: 8 }}
              >
                {t("Add-Location")}
              </LoadingButton>
            </Stack>
          </Grid>
        )}
        {userLocation.latitude > 0 && (
          <Grid>
            <Typography
              variant="h5"
              sx={{
                mt: 2,
                mb: 1,
                color: theme.palette.grey[500],
                fontWeight: 600,
              }}
            >
              {t("Pin-location-on-map")}:
            </Typography>
            <MapComponent mapLocation={handleMapLocation}/>
          </Grid>
        )}
        <Grid>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{
              paddingTop: 4,
            }}
          >
            <img src={imgEMS} style={{ height: 20, width: 20 }} />
            <Typography
              variant="h5"
              sx={{
                paddingTop: "1px",
                color: fwLightColor,
                fontWeight: 500,
              }}
            >
              {t("Registered-to-EMS-zone")}:
            </Typography>
          </Stack>
          <Divider
            sx={{
              opacity: 1,
              paddingBottom: 1,
              borderColor: theme.palette.primary.light,
            }}
          />

          <FormControl
            fullWidth
            error={Boolean(
              formik.touched.additionalLocationInformation &&
                formik.errors.additionalLocationInformation
            )}
            sx={{ ...theme.typography.customInput, pt: 1 }}
          >
            <InputLabel htmlFor="additionalLocationInformation">
            {t("Additional-information-to-your-location")}
            </InputLabel>
            <OutlinedInput
              id="additionalLocationInformation"
              type="text"
              value={formik.values.additionalLocationInformation}
              name="additionalLocationInformation"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.additionalLocationInformation &&
              formik.errors.additionalLocationInformation && (
                <FormHelperText error id="additionalLocationInformation">
                  {formik.errors.additionalLocationInformation}
                </FormHelperText>
              )}
          </FormControl>

          <Box sx={{ mt: 2 }}>
            <LoadingButton
              loading={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              {formik.isSubmitting
                ? t("Please-wait")
                : t("Update-Location-Details")}
            </LoadingButton>
          </Box>
        </Grid>
      </form>
    </>
  );
};

export default LocationDetails;
