import { AddCircleOutlineRounded, EditRounded } from "@mui/icons-material";
import { IconButton, Typography, CardContent, CardHeader, Link } from "@mui/material";
import React, { useState } from "react";

import imgskill from 'assets/images/ImgSkill.png';
import CertificateDialog from "./CertificateDialog";
import { formatStrDtInDD_MM_YYYYFormat, parseCertDate } from "views/utilities/utility";
import SubCardSkillDetails from "ui-component/cards/SubCardSkillDetails";
import { Stack } from "@mui/system";
import SubCardSkill from "ui-component/cards/SubCardSkill";
import { useTranslation } from "react-i18next";



const SkillDetail = ({ data }) => {
    const certificateData = data.certificate ?? undefined;
    const canEdit = certificateData ? true : false;
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const handlerIconButton = () => {
        setOpen(true);
    }

    const handleCloseDialog = () => {
        setOpen(false);
    }
 
    return (
        <>
            <SubCardSkill>
                <CardHeader sx={{ paddingTop: 1, paddingLeft: 2, paddingRight: 2, paddingBottom: 1 }}
                    avatar={
                        <img src={imgskill} style={{ zIndex: 2, marginTop: '-15px', height: '40px' }} height={40} />
                    }
                    action={
                        <IconButton aria-label={t("Add-certificate")} onClick={handlerIconButton}>
                            {canEdit && <EditRounded />}
                            {!canEdit && <AddCircleOutlineRounded />}
                        </IconButton>
                    }
                    title={data.name}
                />
                <CardContent>

                    <SubCardSkillDetails title={certificateData && t("Certificate-Details") }>
                        {
                            canEdit &&
                            <Stack direction={{ xs: 'column', sm: 'row' }}
                                spacing={{ xs: 2, sm: 2, md: 4 }}
                                justifyContent="center"
                                alignContent='center'
                            >
                                {
                                    certificateData.originalPath &&
                                    <Link sx={{ paddingTop: '1.3em', textDecoration: 'none' }} align="center" target="_blank" href={certificateData.originalPath}>
                                        {t("Download-Certificate")}
                                    </Link>
                                }
                                <Stack direction='column' alignContent='center'>
                                    <Typography variant="subtitle2" color="text.secondary" align="center">
                                        {t("Certificate-Date")}:
                                    </Typography>
                                    <Typography variant="h6" color="text.primary" align="center">
                                        {formatStrDtInDD_MM_YYYYFormat(parseCertDate(certificateData.certificateDate))}
                                    </Typography>
                                </Stack>
                                <Stack direction='column' alignContent='center' >
                                    <Typography variant="subtitle2" color="text.secondary" align="center">
                                    {t("Valid-Till")}:
                                    </Typography>
                                    <Typography variant="h6" color="text.primary" align="center">
                                        {formatStrDtInDD_MM_YYYYFormat(parseCertDate(certificateData.validTill))}
                                    </Typography>
                                </Stack>
                            </Stack>

                        }
                        {
                            !certificateData &&
                            <Typography variant="subtitle2" color="text.secondary" sx={{ p: '10px' }} align="center">
                                {t("No-certificate-details-added-so-far")}.
                            </Typography>
                        }
                    </SubCardSkillDetails>


                </CardContent>
            </SubCardSkill>
            <CertificateDialog open={open} canEdit={canEdit} handleClose={handleCloseDialog} skillId={data.id} certificate={data.certificate} />
        </>
    );
}

export default SkillDetail;