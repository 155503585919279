import PropTypes from 'prop-types'; 

// material-ui
import {
  Box,
} from '@mui/material';

// project import
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //


const SendCardWrapper = ({ header, subHeader, headerColor, icon, action, children, ...other }) => {

  return (
    <MainCard        
      title={header} 
      subheader={subHeader}
      headerColor={headerColor}
      whiteForegroundColor={'white'}
      iconUrl={icon}      
      action={action}
      sx={{
        backgroundColor: {headerColor},
        maxWidth: { xs: 576, lg: 992 },
        margin: { xs: 2.5, md: 3 },
        '& > *': {
          flexGrow: 1,
          flexBasis: '50%'
        }
      }}
      content={false}
      {...other}
    >
      <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>{children}</Box>
    </MainCard>
  );
}

SendCardWrapper.propTypes = {
  children: PropTypes.node
};

export default SendCardWrapper;
