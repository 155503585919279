import React, { useContext,  useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { 
  Box, 
  FormControl, 
  FormHelperText,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
  useMediaQuery,
  MenuItem,
  Select,
  styled,
  Badge,
  Avatar,
  CardMedia,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

// third party
import * as Yup from "yup";
import { useFormik } from "formik";

// project imports
import { updateProfileDetails } from "apis/httpcom";
import { AppContext } from "context/AppContext";
import { getFullLanguageName } from "views/utilities/utility";
import OkDialog from "ui-component/dialogs/OkDialog";
import { Stack } from "@mui/system"; 
import ProfileImageButton from "./skills/profile-components/ProfileImageButton";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";



const ProfileDetails = ({ ...others }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const ctx = useContext(AppContext);
  const [dialogOpen, setdialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [language, setLanguage] = useState(ctx.profileLanguage ?? "en");
  const { t, i18n } = useTranslation();

  const [{ title, message, isSuccess }, setDialogState] = useState({
    title: "",
    message: "",
    isSuccess: false,
  });
 

  const [selectedImage, setSelectedImage] = useState();

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  const handleDialogClose = () => {
    setIsSubmitting(false);
    setdialogOpen(false); 
  };

  const handleLanguageChange = (event) => {
    //console.log(event.target.value);
    i18n.changeLanguage(event.target.value);
    setLanguage(event.target.value);
  };

  const handleSelectedImage = (imgBase64) => { 
    setSelectedImage(imgBase64);
  };

  const handleSubmit = async (event) => {
    setIsSubmitting(true);

    //check if user has selected valid image file
    var imageBase64 = selectedImage ?? "";

    //send request
    const response = await updateProfileDetails(
      ctx.authToken,
      event.firstName,
      event.lastName ?? '',
      language,
      imageBase64
    );

    let data = await response.json();
    console.log(data);

    if (data && data.level) {
      if (data.level === 1) {
        //parsing response
        ctx.updateProfileFirstName(data.returnValue.profileFirstName);
        ctx.updateProfileLastName(data.returnValue.profileLastName);
        ctx.updateProfileLanguage(data.returnValue.profileLanguage);
        ctx.updateProfileImage(data.returnValue.profileImage);

        setLanguage(data.returnValue.profileLanguage);
        
        toast.success(
          <>
            <Stack>
              <Typography variant="subtitle1">{t("Profile-Updated")}!</Typography>
              <Typography variant="caption">
              {t("Profile details updated successfully")}.
              </Typography>
            </Stack>
          </>,
          {
            position: "top-right",
          }
        );

        setIsSubmitting(false);
        return;
      }
    }

    toast.error(
      <>
        <Stack>
          <Typography variant="subtitle1">{t("Couldnt-update")}!</Typography>
          <Typography variant="caption">
            {t("Couldn't to update profile details, please try again after some time")}.
          </Typography>
        </Stack>
      </>,
      {
        position: "top-right",
      }
    );
    setIsSubmitting(false);
  };
 
  const getLanguage = (languageCode) => {
    if (!languageCode) {
      return "en";
    }
    return languageCode;
  };

  const formik = useFormik({
    initialValues: {
      firstName: ctx.profileFirstName,
      lastName: ctx.profileLastName,
      language: getLanguage(language),
      timezone: ctx.profileTimeZone,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().max(50).required(t("First-Name-is-required")) 
    }),
    onSubmit: handleSubmit,
  });

  const getSourceImage = () => {
    const defaultImg = '/assets/images/DefaultProfile.png';
    if(selectedImage){
      //return default source image
      return defaultImg;
    }

    if(ctx.profileImage){
      return ctx.profileImage
    }

    return defaultImg
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              marginTop: "5px",
              marginBottom: "30px",
            }}
          > 
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={<ProfileImageButton handleSelectedImageBase64={handleSelectedImage}/>}
            >
              <Avatar
                alt=""
                //src={ctx.profileImage ? ctx.profileImage : '/assets/images/DefaultProfile.png'}
                src={getSourceImage()}
                sx={{ width: 84, height: 84 }}
              >
                {selectedImage && (
                    <CardMedia
                      component="img"
                      src={`data:image/png;base64, ${selectedImage}`}
                    />
                  )}

                </Avatar>
            </Badge>
          </Grid>
 

          <Typography
            variant="h5"
            sx={{
              mt: 3,
              mb: 1,
              color: theme.palette.grey[500],
              fontWeight: 600,
            }}
          >            
            {t("Contact-Details")}
          </Typography>

          <Grid container spacing={matchDownSM ? 0 : 2}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={Boolean(
                  formik.touched.firstName && formik.errors.firstName
                )}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel htmlFor="firstName">{t("First-Name")}</InputLabel>
                <OutlinedInput
                  id="firstName"
                  type="firstName"
                  value={formik.values.firstName}
                  name="firstName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text--register"
                  >
                    {formik.errors.firstName}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={Boolean(
                  formik.touched.lastName && formik.errors.lastName
                )}
                sx={{ ...theme.typography.customInput }}
              >
                <InputLabel htmlFor="firstName">{t("Last-Name")}</InputLabel>
                <OutlinedInput
                  id="lastName"
                  type="lastName"
                  value={formik.values.lastName}
                  name="lastName"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text--register"
                  >
                    {formik.errors.lastName}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Typography
            variant="h5"
            sx={{
              mt: 3,
              mb: 2,
              color: theme.palette.grey[500],
              fontWeight: 600,
            }}
          >
            {t("Application-Settings")}
          </Typography>

          <FormControl fullWidth sx={{ mb: 1 }}>
            <InputLabel id="language">{t("Select-Language")}</InputLabel>

            <Select
              labelId="language"
              id="language"
              name="language"
              key={language}
              value={language}
              label="Select Language"
              onChange={handleLanguageChange}
            >
              {ctx.availableLanguages &&
                ctx.availableLanguages.map((lng) => (
                  <MenuItem key={lng} value={lng}>
                    {getFullLanguageName(lng)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl
            fullWidth
            sx={{ mt: 2, ...theme.typography.customInput }}
            error={Boolean(formik.touched.timezone && formik.errors.timezone)}
          >
            <InputLabel htmlFor="timezone">{t("Timezone")}</InputLabel>
            <OutlinedInput
              disabled
              id="timezone"
              type="timezone"
              value={formik.values.timezone}
              name="timezone"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
            {formik.touched.timezone && formik.errors.timezone && (
              <FormHelperText error id="standard-weight-helper-text--register">
                {formik.errors.timezone}
              </FormHelperText>
            )}
          </FormControl>

          <Box sx={{ mt: 2 }}>
            <LoadingButton
              loading={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              {formik.isSubmitting
                ? t("Please-wait")
                : t("Update-Profile-Details")}
            </LoadingButton>
          </Box>
        </Grid>
      </form>

      {dialogOpen && (
        <OkDialog
          title={title}
          message={message}
          isSuccess={isSuccess}
          open={dialogOpen}
          handleClose={handleDialogClose}
        />
      )}
    </>
  );
};

export default ProfileDetails;
