import { useContext, useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { 
  Avatar,
  Box, 
  CardMedia,
  FormControl,
  FormHelperText,
  Grid, 
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@mui/material";

// third party 
import { useFormik } from "formik";

import { LoadingButton } from "@mui/lab";

// project imports
import { sendEvent } from "apis/httpcom";
import { AppContext } from "context/AppContext";
import { Stack } from "@mui/system";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import CropOriginalIcon from "@mui/icons-material/Wallpaper";
import SelectOrCapturePhoto from "views/common-components/SelectOrCapturePhoto";
import { useTranslation } from "react-i18next";


const SendEventForm = ({ event, ...others }) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [successMsg, setSuccessMsg] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState();

  const ctx = useContext(AppContext);
  const { t } = useTranslation();

  useEffect(() => {}, []);

  const formik = useFormik({
    initialValues: {
      eventId: event.eventId,
      eventName: event.name,
      message: "",
    }, 
    onSubmit: async (values, { resetForm }) => { 
      try {
        const response = await sendEvent(
          ctx.authToken,
          values.eventId,
          values.message,
          selectedPhoto ?? "",
          ctx.latitude ?? 0,
          ctx.longitude ?? 0,
        );

        if (response.status === 200) {
          var data = await response.json(); 
          if (data && data.level) {
            if (data.level === 1) { 
              resetForm();
              setSuccessMsg(true);
              //setOpen(true);
              return;
            }
          }
 
        }
      } catch (error) {
        console.log("sendEvent ex", error);
      }
    },
  });

  const handleSelectedImage = (imageBase64) => { 
    setSelectedPhoto(imageBase64);
  };

  return (
    <>
      {!successMsg && (
        <form onSubmit={formik.handleSubmit}>
           
          <Grid container direction={"column"} spacing={2} sx={{ pt: 2 }}>
            <Grid item container spacing={matchDownSM ? 0 : 2}>
              <Typography
                variant="h5"
                sx={{
                  ml: 2,
                  color: theme.palette.grey[500],
                  fontWeight: 600,
                }}
              >
                {t("Attach-Photo")}
              </Typography>
            </Grid>

            <Grid
              item
              container
              spacing={matchDownSM ? 0 : 2}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item xs={3} justifyContent="center" alignItems="center">
                <Avatar
                  alt="User Profile Image"
                  sx={{
                    bgcolor: "#EEF2F6",
                    borderColor: "#C3C4C6",
                    borderRadius: 3,
                    width: 60,
                    height: 60,
                    margin: "8px 0 8px 8px !important",
                  }}
                >
                  {selectedPhoto && (
                    <CardMedia
                      component="img"
                      src={`data:image/png;base64, ${selectedPhoto}`}
                    />
                  )}

                  {!selectedPhoto && (
                    <CropOriginalIcon style={{ fontSize: 40, color: "grey" }} />
                  )}
                </Avatar>
              </Grid>
              <Grid item xs={9}>
                <SelectOrCapturePhoto
                  handleSelectedImageBase64={handleSelectedImage}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={10}>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(
                    formik.touched.message && formik.errors.message
                  )}
                  sx={{ ...theme.typography.customInput }}
                >
                  <OutlinedInput
                    placeholder={t("Additional-information-for-Emergency-Responders")}
                    id="message"
                    type="message"
                    value={formik.values.message}
                    name="message"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    multiline
                    rows={3}
                  />
                  {formik.touched.message && formik.errors.message && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text--register"
                    >
                      {formik.errors.message}
                    </FormHelperText>
                  )}
                </FormControl>

                <Grid item xs={12} sx={{ mt: 1 }}>
                  <LoadingButton
                    sx={{
                      textTransform: "none",
                    }}
                    loading={formik.isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {formik.isSubmitting
                      ? t('Please-wait')
                      : t('Send-event-message')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>

            {formik.errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{formik.errors.submit}</FormHelperText>
              </Box>
            )}
          </Grid>
        </form>
      )}

      {successMsg && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: 1, height: "20vh" }}
        >
          <Stack
            direction="column"
            alignItems={"center"}
            spacing={2}
            justifyContent="center"
            sx={{ pt: 2, pb: 4 }}
          >
            <CheckCircleOutlineOutlined
              style={{ color: "green", fontSize: "45px" }}
            />
            <Typography variant="caption">{t('Event-sent-successfully')}.</Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default SendEventForm;
