// material-ui
import { useTheme } from '@mui/material/styles';
import logo from 'assets/images/findwhere-logo-200.png';

const LogoNav = ({width = 180}) => {
  const theme = useTheme();

  return ( 
    <img src={logo} alt="BHV Alert" width={width} /> 
  );
};

export default LogoNav;
